import React from 'react'
import styled from 'styled-components';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import helemt from 'react-helmet';

const VerticalAligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title='404' />
    <VerticalAligner>
      <h1>OH NO!</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </VerticalAligner>
  </Layout>
)

export default NotFoundPage
